import Vue from "vue"
import _ from "lodash"
import ep from "~/api/endpoint"

const state = () => ({
  disabled_active_menu: false,
  menu_list: [
    {
      data_id: "global.side-nav-menu.smartchat",
      path_id: "smartchat",
      // [TODO : Nick] ปรับ default หน้า smartchat ให้เป็นหน้าแชทของคุณชั่วคราว
      // path: "/smartchat",
      path: "/smartchat?status=me",
      name: "แชท",
      icon: require("~/assets/images/chat_bubble_outline_24px.svg"),
      channel_filter: true,
      sub_menu_list: [
        {
          name: "",
          childs: [
            {
              name: "แชทที่ส่งให้คุณ",
              path_id: "smartchat",
              path: "/smartchat?status=assign-me",
              icon: "arrow_forward",
              total: 0,
              data_tracking_id: "smartchat_assign_tp_me_click",
              description: "แชทที่ส่งให้คุณ",
              secondary_menu: true,
              aggregate_keys: "members_assigning_to",
              is_my_aggregate_count: true,
            },
            {
              name: "แชทที่ส่งให้ทีม",
              path_id: "smartchat",
              path: "/smartchat?status=team-setting",
              icon: "arrow_forward",
              total: 0,
              data_tracking_id: "smartchat_assign_tp_team_click",
              description: "แชทที่ส่งให้ทีม",
              secondary_menu: true,
              aggregate_keys: "teams_assignment",
              is_my_aggregate_count: true,
              is_assigning: true,
              is_assigning_team_menu: true,
              id: "teams_assignment",
            },
            {
              name: "แชทของคุณ",
              path_id: "smartchat",
              path: "/smartchat?status=me",
              icon: "record_voice_over",
              total: 0,
              data_tracking_id: "smartchat_target_me_click",
              description: "แชทที่คุณกำลังดูแลอยู่",
              aggregate_keys: "members_assignment",
              is_my_aggregate_count: true,
            },
            {
              name: "แชทปักหมุด",
              path_id: "smartchat",
              path: "/smartchat?status=pinned",
              icon: "push_pin",
              total: 0,
              data_tracking_id: "smartchat_target_star_click",
              description:
                "แชทที่กดติดตามเอาไว้<br />เป็นลิสต์ส่วนตัวของสมาชิกแต่ละคน<br />ไม่แชร์ถึงกัน",
              aggregate_keys: "starred_by",
              is_my_aggregate_count: true,
            },
          ],
        },
        {
          name: "แชทธุรกิจ",
          childs: [
            {
              name: "ทั้งหมด",
              path_id: "smartchat",
              path: "/smartchat",
              path_query: {},
              icon: "chat_bubble_outline",
              total: 0,
              data_tracking_id: "smartchat_target_all_click",
              description:
                "แชททุกสถานะ ทุกช่องทางของธุรกิจ<br />ไม่ว่าสมาชิกคนไหนจะดูแลอยู่<br />หรือแชทบอทกำลังตอบ",
              aggregate_keys: "all",
              is_my_aggregate_count: false,
            },
            {
              name: "เรียกสมาชิก",
              path_id: "smartchat",
              path: "/smartchat?status=request",
              icon: "account_circle",
              total: 0,
              data_tracking_id: "smartchat_target_request_click",
              description:
                "แชทที่ต้องการสนทนากับสมาชิก<br />สมาชิกสามารถเลือกลูกค้าเพื่อเริ่มแชท<br />ได้ที่นี่ ",
              aggregate_keys: "request",
              is_my_aggregate_count: false,
            },
          ],
        },
        {
          name: "แชทบอท",
          childs: [
            {
              name: "แชทบอทตอบแล้ว",
              path: "/smartchat?status=bot",
              vector_icon: "bot_reply",
              total: 0,
              data_tracking_id: "smartchat_target_bot_click",
              description:
                'แชทที่แชทบอทตอบได้ตามที่ตั้งค่าไว้<br />สมาชิกควรเข้ามา "รับแชท"<br />หากคำตอบยังไม่สมบูรณ์ หรือ "จบแชท"<br />เพื่อจบการสนทนา',
              aggregate_keys: "bot_replied",
              is_my_aggregate_count: false,
            },
            {
              name: "แชทบอทตอบไม่ได้",
              path: "/smartchat?status=no-bot",
              vector_icon: "bot_not_reply",
              total: 0,
              data_tracking_id: "smartchat_target_nobot_click",
              description:
                'แชทของลูกค้าที่แชทบอทตอบไม่ได้<br />สมาชิกควรเข้ามา "รับแชท"<br />เพื่อดูแลลูกค้าต่อ<br />และปรับแชทบอทเพื่อให้สามารถตอบได้ดียิ่งขึ้นในครั้งหน้า',
              aggregate_keys: "fallback",
              is_my_aggregate_count: false,
            },
          ],
        },
        {
          name: "แชทของทีม",
          childs: [
            {
              name: "แชทของทีม",
              path_id: "smartchat",
              path: "/smartchat?status=agent",
              icon: "record_voice_over",
              total: 0,
              description: "แชทที่สมาชิกคนอื่นกำลังดูแลอยู่",
              aggregate_keys: "members",
              is_my_aggregate_count: false,
            },
          ],
        },
        {
          name: "จบบริการ",
          is_align_bottom: true,
          childs: [
            {
              name: "จบแชทแล้ว",
              path: "/smartchat?status=end-case",
              vector_icon: "end_case",
              total: 0,
              data_tracking_id: "smartchat_target_endcase_click",
              description:
                'แชทที่สมาชิกดูแลเรียบร้อยแล้ว และกด<br />"จบแชท" เมื่อจบการสนทนา',
              aggregate_keys: "end_case",
              is_my_aggregate_count: false,
            },
          ],
        },
      ],
    },
    {
      data_id: "global.side-nav-menu.contact",
      path_id: "contact",
      path: "/contact",
      name: "รายชื่อ",
      icon: require("~/assets/images/contacts_24px.svg"),
      is_padding: true,
      channel_filter: true,
      sub_menu_list: [
        {
          name: "",
          childs: [
            {
              name: "ทั้งหมด",
              path_id: "contact",
              path: "/contact",
              // total: 0,
              data_tracking_id: "contact_all_click",
            },
          ],
        },
        {
          name: "กลุ่มเป้าหมายแนะนำโดย Oho",
          is_collapse: false,
          childs: [
            {
              name: "ลูกค้าใหม่ใน 7 วัน",
              path_id: "new_contact",
              path: "/contact/new_contact",
              total: 0,
              data_tracking_id: "contact_target_suggestion_new7day_click",
            },
            {
              name: "คุยมากใน 7 วัน",
              path_id: "top_rank_chat_contact",
              path: "/contact/top_rank_chat_contact",
              total: 0,
              data_tracking_id: "contact_target_suggestion_hotchat7day_click",
            },
            {
              name: "ยังไม่เริ่มคุย",
              path_id: "greeting_no_chat",
              path: "/contact/greeting_no_chat",
              total: 0,
              data_tracking_id: "contact_target_suggestion_nochat_click",
            },
            // {
            //   name: "บล็อค",
            //   path: "/contact?filter=block",
            //   total: 0,
            // },
          ],
        },
      ],
    },
    {
      data_id: "global.side-nav-menu.arp",
      path_id: "auto-reply",
      path: "/auto-reply",
      name: "แชทบอท",
      icon: require("~/assets/images/forum_24px.svg"),
      is_padding: false,
      channel_filter: false,
      sub_menu_list: [
        {
          name: "",
          childs: [
            {
              name: "ผังแชทบอท",
              path_id: "blueprint-mapping",
              path: "/auto-reply/blueprint-mapping",
              icon: "account_tree",
              total: 0,
              data_tracking_id: "arp_blueprint_mapping_click",
            },
            {
              name: "เปิด-ปิด แชทบอท",
              path_id: "toggle-chatbot",
              path: "/auto-reply/toggle-chatbot",
              icon: "toggle_off",
              total: 0,
              data_tracking_id: "arp_toggle_chatbot_click",
            },
          ],
        },
        {
          name: "ข้อความตอบกลับ",
          childs: [
            {
              name: "ตอบกลับแชท",
              path_id: "auto-reply",
              path: "/auto-reply",
              icon: "forum",
              total: 0,
              data_tracking_id: "arp_main_click",
            },
            {
              name: "ตอบกลับคอมเมนต์",
              path: "/auto-reply/facebook-comment",
              icon: "comment",
              total: 0,
            },
          ],
        },
        {
          name: "ฟังก์ชัน",
          childs: [
            {
              name: "ข้อความต้อนรับ",
              path_id: "greeting-message",
              path: "/auto-reply/greeting-message",
              icon: "waving_hand",
              total: 0,
              data_tracking_id: "arp_platform_greeting_click",
            },
            {
              name: "ข้อความต้อนรับ (ติดต่อซ้ำ)",
              path_id: "welcome-back-message",
              path: "/auto-reply/welcome-back-message",
              icon: "emoji_people",
              total: 0,
              data_tracking_id: "arp_platform_welcome-back-message_click",
            },
            {
              name: "เมนูหลัก",
              path_id: "main-menu",
              path: "/auto-reply/main-menu",
              icon: "home",
              total: 0,
              data_tracking_id: "arp_platform_main-menu_click",
            },
            {
              name: "ข้อความเมื่อบอทตอบไม่ได้",
              path_id: "fallback",
              path: "/auto-reply/fallback",
              icon: "feedback",
              total: 0,
              data_tracking_id: "arp_platform_fallback_click",
            },
            {
              name: "ข้อความเมื่อเรียกสมาชิก",
              path_id: "inform-admin",
              path: "/auto-reply/inform-admin",
              icon: "account_circle",
              total: 0,
            },
          ],
        },
        {
          name: "ฟังก์ชันพิเศษ",
          childs: [
            {
              name: "Messenger เมนู",
              path_id: "messenger",
              path: "/auto-reply/messenger",
              icon: "",
              vector_icon: "messenger_outline",
              total: 0,
              data_tracking_id: "arp_fb_messenger_menu_click",
            },
            {
              name: "LINE OA ริชเมนู",
              path_id: "rich-menu",
              path: "/auto-reply/rich-menu",
              icon: "",
              vector_icon: "line_oa_outline",
              total: 0,
              data_tracking_id: "arp_line_rich-menu_click",
            },
          ],
        },
      ],
    },
    {
      data_id: "global.side-nav-menu.dashboard",
      path_id: "dashboard",
      path: "/dashboard",
      name: "แดชบอร์ด",
      icon: require("~/assets/images/dashboard_24px.svg"),
      channel_filter: true,
    },
    {
      data_id: "global.side-nav-menu.case",
      path_id: "case",
      path: "/case",
      name: "คลังเคส",
      icon: require("~/assets/images/icon-case-menu.svg"),
      is_padding: true,
      channel_filter: true,
      is_sub_menu_visible: true,
      sub_menu_list: [
        {
          name: "",
          childs: [
            {
              name: "เคสของคุณ",
              path_id: "case-my",
              path: "/case/my",
              total: 0,
              data_tracking_id: "cases_type_my_cases_click",
              is_my_aggregate_count: true,
              aggregate_keys: "assigning_to",
            },
          ],
        },
        {
          name: "",
          divider: true,
          childs: [
            {
              name: "ทั้งหมด",
              path_id: "case-all",
              path: "/case",
              total: 0,
              data_tracking_id: "cases_type_all_click",
              is_my_aggregate_count: false,
              aggregate_keys: "all",
            },
          ],
        },
        {
          name: "แยกตามสถานะเคส",
          childs: [
            // {
            //   name: "เรียกแอดมิน",
            //   path_id: "cases-request",
            //   path: "/cases/request",
            //   total: 0,
            //   data_tracking_id: "cases_type_request_click",
            // },
            {
              name: "กำลังดูแล",
              path_id: "case-inprogress",
              path: "/case/inprogress",
              total: 0,
              data_tracking_id: "cases_type_inprogress_click",
              is_my_aggregate_count: false,
              aggregate_keys: "inprogress",
            },
            {
              name: "ปิดเคสแล้ว",
              path_id: "case-done",
              path: "/case/done",
              total: 0,
              data_tracking_id: "cases_type_done_click",
              is_my_aggregate_count: false,
              aggregate_keys: "done",
            },
            {
              name: "สแปม",
              path_id: "case-spam",
              path: "/case/spam",
              total: 0,
              data_tracking_id: "cases_type_spam_click",
              is_my_aggregate_count: false,
              aggregate_keys: "spam",
            },
            {
              name: "เพิกเฉย",
              path_id: "case-ignore",
              path: "/case/ignore",
              total: 0,
              data_tracking_id: "cases_type_ignore_click",
              is_my_aggregate_count: false,
              aggregate_keys: "ignore",
            },
          ],
        },
      ],
    },
    {
      data_id: "global.side-nav-menu.media-library",
      path_id: "media-library",
      path: "/media-library",
      name: "คลังข้อมูล",
      icon: require("~/assets/images/perm_media.svg"),
      is_padding: true,
      channel_filter: false,
      sub_menu_list: [
        {
          name: "คลังคำตอบ",
          childs: [
            {
              name: "รูปแบบคำตอบ",
              path_id: "setting_saved_reply",
              path: "/media-library/saved-reply",
              icon: "chat",
              data_tracking_id: "media-library_save-reply_click",
            },
          ],
        },
        {
          name: "คลังสื่อ",
          childs: [
            {
              name: "ทั้งหมด",
              path_id: "media-library",
              path: "/media-library",
              icon: "",
              total: 0,
              data_tracking_id: "media-library_type_all_click",
            },
            {
              name: "รูปภาพ",
              path_id: "image",
              path: "/media-library/image",
              icon: "image",
              total: 0,
              data_tracking_id: "media-library_type_img_click",
            },
            {
              name: "วีดีโอ",
              path_id: "video",
              path: "/media-library/video",
              icon: "videocam",
              total: 0,
              data_tracking_id: "media-library_type_video_click",
            },
            {
              name: "ไฟล์",
              path_id: "file",
              path: "/media-library/file",
              icon: "attachment",
              total: 0,
              data_tracking_id: "media-library_type_file_click",
            },
          ],
        },
      ],
    },
    {
      data_id: "global.side-nav-menu.automation",
      path_id: "automation",
      // path: "/automation/chat-status-setting",
      path: "/automation/team-setting",
      name: "ออโตเมชัน",
      icon: require("~/assets/images/auto.svg"),
      is_padding: true,
      channel_filter: false,
      is_new_feature: true,
      is_disabled: true,
      is_show_tooltip_on_disabled: false,
      tooltip_content: `
        ออโตเมชัน
        <div style="font-size: 10px;">Coming soon!</div>
      `,
      sub_menu_list: [
        {
          name: "ส่งแชทอัตโนมัติ",
          childs: [
            // {
            //   data_id: "global.sub-menu.link-auto-assign-chat-status-setting",
            //   name: "ส่งตามสถานะแชท",
            //   path_id: "automation_chat_status_setting",
            //   path: "/automation/chat-status-setting",
            //   vector_icon: "auto_assign_chat_status",
            //   data_tracking_id: "automation_chat_status_setting_click",
            // },
            {
              data_id: "global.sub-menu.link-auto-assign-team-setting",
              name: "ส่งตามทีม",
              path_id: "automation_team_setting",
              path: "/automation/team-setting",
              vector_icon: "auto_assign_team",
              data_tracking_id: "automation_team_setting_click",
            },
          ],
        },
      ],
    },
    {
      path_id: "broadcast",
      path: "/broadcast",
      name: "บรอดแคสต์",
      icon: require("~/assets/images/icon-campaign.svg"),
      is_padding: false,
      channel_filter: false,
      is_new_feature: true,
      is_disabled: true,
      is_show_tooltip_on_disabled: false,
      tooltip_content: `
        บรอดแคสต์
        <div style="font-size: 10px;">Coming soon!</div>
      `,
      sub_menu_list: [
        {
          name: "",
          childs: [
            {
              name: "ทั้งหมด",
              path_id: "broadcast_status_all",
              path: "/broadcast",
              data_tracking_id: "broadcast_status_all_click",
              total: 0,
            },
          ],
        },
        {
          name: "แยกตามสถานะ",
          childs: [
            {
              name: "แบบร่าง",
              path_id: "broadcast_status_draft",
              path: "/broadcast/status/draft",
              data_tracking_id: "broadcast_status_draft_click",
              total: 0,
            },
            {
              name: "ตั้งเวลาส่ง",
              path_id: "broadcast_status_scheduled",
              path: "/broadcast/status/scheduled",
              data_tracking_id: "broadcast_status_scheduled_click",
              total: 0,
            },
            {
              name: "ส่งสำเร็จ",
              path_id: "broadcast_status_success",
              path: "/broadcast/status/success",
              data_tracking_id: "broadcast_status_success_click",
              total: 0,
            },
            {
              name: "ส่งไม่สำเร็จ",
              path_id: "broadcast_status_failed",
              path: "/broadcast/status/failed",
              data_tracking_id: "broadcast_status_failed_click",
              total: 0,
            },
          ],
        },
      ],
    },
    {
      path_id: "onboarding",
      path: "",
      name: "เรียนรู้การใช้งาน",
      is_bottom: true,
      icon: require("~/assets/images/onboarding_24px.svg"),
      channel_filter: false,
    },
    {
      data_id: "global.side-nav-menu.help",
      path_id: "help",
      path: "https://help.oho.chat/user-manual",
      name: "คู่มือการใช้งานและช่วยเหลือ",
      is_bottom: true,
      is_external_link: true,
      icon: require("~/assets/images/help_24px.svg"),
    },
    {
      data_id: "global.side-nav-menu.setting",
      path_id: "setting",
      path: "/setting",
      name: "การตั้งค่า",
      icon: require("~/assets/images/settings_24px.svg"),
      is_bottom: true,
      is_padding: true,
      channel_filter: false,
      sub_menu_side: true,
      sub_menu_list: [
        {
          name: "ตั้งค่าธุรกิจของคุณ",
          childs: [
            {
              name: "ข้อมูลทั่วไป",
              path_id: "setting_business",
              path: "/setting",
              icon: "business",
              data_tracking_id: "setting_business_info_click",
            },
            {
              name: "สมาชิก",
              path_id: "setting_member",
              path: "/setting/member",
              icon: "support_agent",
              data_tracking_id: "setting_business_member_click",
            },
            {
              name: "ทีม",
              path_id: "setting_team",
              path: "/setting/team",
              icon: "groups",
              data_tracking_id: "setting_business_team_click",
            },
            {
              name: "การตั้งค่าช่องทาง",
              path_id: "setting_integration",
              path: "/setting/integration",
              icon: "integration_instructions",
              data_tracking_id: "setting_business_integration_click",
            },
            {
              name: "แพ็กเกจและการชำระเงิน",
              path_id: "setting_payment",
              path: "/setting/payment",
              icon: "payment",
              data_tracking_id: "setting_business_payment_click",
            },
          ],
        },
        {
          name: "Developer",
          is_collapse: true,
          collapse_menu: true,
          icon: "app_settings_alt",
          childs: [
            {
              name: "Webhook",
              path_id: "webhook",
              path: "/setting/webhook",
              icon: "webhook",
              data_tracking_id: "setting_business_webhook_click",
            },
          ],
        },
        {
          name: "ตั้งค่าอื่น ๆ",
          childs: [
            {
              name: "ช่องทางติดต่อ",
              path_id: "setting_contact_channels",
              path: "/setting/contact-channels",
              icon: "phone",
              data_tracking_id: "setting_business_contact_click",
            },
            {
              name: "เวลาทำการ",
              path_id: "setting_business_hours",
              path: "/setting/business-hours",
              icon: "access_time",
              data_tracking_id: "setting_business_hours_click",
            },
            {
              name: "วันหยุดทำการ",
              path_id: "setting_business_holidays",
              path: "/setting/business-holidays",
              icon: "calendar_today",
              data_tracking_id: "setting_business_holiday_click",
            },
            // {
            //   name: "เรียกแอดมิน",
            //   path_id: "setting_inform_admin",
            //   path: "/setting/inform-admin",
            //   icon: "record_voice_over",
            //   data_tracking_id: "setting_business_msg-request_click",
            // },
          ],
        },
      ],
    },
  ],
})

const mutations = {
  setMenu(state, { index, menu }) {
    Vue.set(state.menu_list, index, menu)
  },
  setDisabledActiveMenu(state, disable) {
    state.disabled_active_menu = disable
  },
  setActiveAutomationMenu(state) {
    const automation_menu_index = _.findIndex(state.menu_list, [
      "path_id",
      "automation",
    ])

    if (automation_menu_index >= 0) {
      state.menu_list[automation_menu_index].is_disabled = false
      state.menu_list[automation_menu_index].tooltip_content = "ออโตเมชัน"
    }
  },
  setActiveBroadcastMenu(state) {
    const broadcast_menu_index = _.findIndex(state.menu_list, [
      "path_id",
      "broadcast",
    ])

    if (broadcast_menu_index >= 0) {
      state.menu_list[broadcast_menu_index].is_disabled = false
      state.menu_list[broadcast_menu_index].tooltip_content = "บรอดแคสต์"
    }
  },
  setHideCaseSubMenu(state) {
    const case_menu_index = _.findIndex(state.menu_list, ["path_id", "case"])

    if (case_menu_index >= 0) {
      state.menu_list[case_menu_index].is_sub_menu_visible = false
    }
  },
}

const actions = {
  getAggregateCount({ rootState }, data = {}) {
    const checked_channels = rootState.checked_channels

    return _.chain(data.agg_data)
      .get(data.obj_key)
      .filter((data) => _.includes(checked_channels, data.channel_id))
      .sumBy("count")
      .value()
  },
  getMyAggregateCount({ rootState }, data = {}) {
    const checked_channels = rootState.checked_channels

    const val = _.chain(data.agg_data)
      .get(data.obj_key)
      .filter((d) => _.includes(checked_channels, d.channel_id))
      .filter((d) => d.member_id === data.my_id)
      .sumBy("count")
      .value()

    return val
  },
  getMyTeamAggregateCount({ rootState }, data = {}) {
    const checked_channels = rootState.checked_channels
    const my_team = _.get(rootState, "oho_member.member.teams") || []

    const val = _.chain(data.agg_data)
      .get(data.obj_key)
      .filter((d) => _.includes(checked_channels, d.channel_id))
      .filter((d) => _.includes(my_team, d.team_id))
      .sumBy("count")
      .value()

    return val
  },
  getTeamAggregateCount({ rootState }, data = {}) {
    const checked_channels = rootState.checked_channels

    const val = _.chain(data.agg_data)
      .get(data.obj_key)
      .filter((agg_data) => _.includes(checked_channels, agg_data.channel_id))
      .filter((agg_res) => agg_res.member_id !== data.my_id)
      .sumBy("count")
      .value()

    return val
  },
  generateMenuMyChat({ rootState }, data = {}) {
    let sub_menu = []
    const checked_channels = rootState.checked_channels
    const agg_data = data.agg_data
    const my_id = data.my_id

    const group_by_team = _.chain(agg_data)
      .get("members_assignment")
      .filter((d) => _.includes(checked_channels, d.channel_id))
      .filter((d) => d.member_id === my_id)
      .groupBy((d) => d.team_id)
      .value()

    for (const team_id in group_by_team) {
      const find_team = _.find(agg_data.teams, (team) => team._id === team_id)
      const count_team_assign = _.chain(group_by_team[team_id])
        .sumBy("count")
        .value()

      if (find_team) {
        sub_menu.push({
          id: my_id,
          team_id: team_id,
          name: find_team.display_name,
          image_url: find_team.logo_url,
          color: find_team.color_hex_code,
          path: `/smartchat?status=assignee&assignee=${my_id}&team=${team_id}&assign-type=me`,
          total: count_team_assign,
          is_team: true,
          is_assign_to_me: true,
        })
      }
    }

    return _.orderBy(sub_menu, ["total", "name"], ["desc", "asc"])
  },
  generateMenuChatAssignToTeam({ rootState }, data = {}) {
    let sub_menu = []
    const checked_channels = rootState.checked_channels
    const agg_data = data.agg_data
    const my_team = data.my_team

    const group_by_team = _.chain(agg_data)
      .get("teams_assignment")
      .filter((d) => _.includes(checked_channels, d.channel_id))
      .filter((d) => _.includes(my_team, d.team_id))
      .groupBy((d) => d.team_id)
      .value()

    for (const team_id in group_by_team) {
      const find_team = _.find(agg_data.teams, (team) => team._id === team_id)
      const count_team_assign = _.chain(group_by_team[team_id])
        .sumBy("count")
        .value()

      if (find_team) {
        sub_menu.push({
          id: team_id,
          team_id: team_id,
          name: find_team.display_name,
          image_url: find_team.logo_url,
          color: find_team.color_hex_code,
          is_team: true,
          path: `/smartchat?status=assign-my-team&team=${team_id}`,
          total: count_team_assign,
        })
      }
    }

    return _.orderBy(sub_menu, ["total", "name"], ["desc", "asc"])
  },
  generateMenuTeamChat({ rootState }, data = {}) {
    let childs = []
    const checked_channels = rootState.checked_channels
    const agg_data = data.agg_data
    const my_id = data.my_id
    const my_team = data.my_team
    const team_orders =
      _.get(rootState, "oho_member.member.member_preferences.team_orders") || []

    // [Sale visibility]
    // This permission is used to display the team list
    // in the chat menu on the SmartChat page
    const all_team_list_permission = this.$permission.validate(
      "chat.team-menu.view"
    )
    const my_team_list_permission = this.$permission.validate(
      "chat.my-team-menu.view"
    )

    // [Sale visibility]
    // This logic is used to check the current member has permission
    // to view only the teams they are a member
    const is_only_my_team_list =
      !all_team_list_permission && my_team_list_permission

    // [Sale visibility]
    // This logic is used to separate the build logic of the "เมนูแชทของทีม"
    // For sales members, they only able to view the teams they are members
    let filter_agg_by_visibility = null
    const filter_agg_by_channel = _.chain(agg_data)
      .get("members_assignment")
      .filter((d) => _.includes(checked_channels, d.channel_id))
      .value()

    if (is_only_my_team_list) {
      filter_agg_by_visibility = _.chain(filter_agg_by_channel)
        .filter((d) => _.includes(my_team, d.team_id))
        .filter((d) => {
          // [Sale visibility]
          // This logic is used to find the team visibility setting
          const find_team = _.find(
            agg_data.teams,
            (team) => team._id === d.team_id
          )
          const sale_visibility = _.get(find_team, "sale_visibility")

          if (sale_visibility === "sale_owner") {
            return _.includes(my_id, d.member_id)
          } else {
            return d
          }
        })
        .value()
    } else {
      filter_agg_by_visibility = filter_agg_by_channel
    }

    const group_agg_by_team = _.chain(filter_agg_by_visibility)
      .groupBy((d) => d.team_id)
      .value()

    for (const team_id in group_agg_by_team) {
      const sub_member = []
      const find_team = _.find(agg_data.teams, (team) => team._id === team_id)
      const find_order_index = _.find(
        team_orders,
        (team) => team._id === team_id
      )
      const order_index = _.get(find_order_index, "order_index")
      const group_by_member = _.chain(group_agg_by_team[team_id])
        .groupBy((d) => d.member_id)
        .value()
      const count_team_assign = _.chain(group_agg_by_team[team_id])
        .filter((d) => d.count)
        .sumBy("count")
        .value()
      const count_team_assigning = _.chain(agg_data)
        .get("teams_assignment")
        .filter((d) => _.includes(checked_channels, d.channel_id))
        .filter((d) => d.team_id === team_id)
        .sumBy("count")
        .value()
      const total_count = count_team_assign + count_team_assigning

      for (const member_id in group_by_member) {
        const find_member = _.find(
          agg_data.members,
          (member) => member._id === member_id
        )
        const count_member_assign = _.chain(group_by_member[member_id])
          .sumBy("count")
          .value()

        if (find_member) {
          sub_member.push({
            id: member_id,
            team_id: team_id,
            name: find_member.display_name,
            image_url: find_member.image_url,
            path: `/smartchat?status=assignee&assignee=${member_id}&team=${team_id}`,
            total: count_member_assign,
          })
        }
      }

      if (find_team) {
        childs.push({
          name: find_team.display_name,
          image_url: find_team.logo_url,
          color: find_team.color_hex_code,
          is_team: true,
          is_team_menu: true,
          path_id: find_team._id,
          id: find_team._id,
          team_id: find_team._id,
          path: `/smartchat?status=team&team=${find_team._id}`,
          icon: "record_voice_over",
          total: total_count,
          description: "",
          aggregate_keys: "",
          is_my_aggregate_count: false,
          sub_menu: _.orderBy(sub_member, ["total", "name"], ["desc", "asc"]),
          order_index: order_index,
        })
      }
    }

    return _.orderBy(childs, ["order_index"], ["asc"])
  },
  async getContactAggregate({ rootState, dispatch }, selected_fields) {
    const checked_channels = rootState.checked_channels

    const contactAggregatePathUrl = [true, "true"].includes(
      process.env.feature_flag_cache_contact_aggregate
    )
      ? ep.cache_contact_aggregate
      : ep.contact_aggregate

    try {
      const agg_res = await this.$ohoMemberApi.$get(contactAggregatePathUrl, {
        // params: {
        // channel_ids: checked_channels,
        // selected_fields,
        // },
      })
      await dispatch("setContactAggregate", agg_res)
    } catch (error) {
      this.$message.error(_.get(error, "response.data"))
    }
  },
  async setContactAggregate(
    { commit, dispatch, state, rootState },
    agg_data = {}
  ) {
    const my_id = _.get(rootState, "oho_member.member._id")
    const my_team = _.get(rootState, "oho_member.member.teams") || []
    const index = state.menu_list.findIndex((m) => m.path_id === "smartchat")
    const menu = _.cloneDeep(state.menu_list[index])

    if (!agg_data) return

    menu.sub_menu_list.forEach(async (s) => {
      if (s.name === "แชทของทีม") {
        // Generate sub menu "แชทของทีม"
        if (_.get(agg_data, "teams_assignment")) {
          s.childs = await dispatch("generateMenuTeamChat", {
            my_id,
            agg_data,
            my_team,
          })
        }
      }

      s.childs.forEach(async (c) => {
        const aggregate_keys = c.aggregate_keys
        if (_.get(agg_data, aggregate_keys)) {
          const data = {
            my_id,
            my_team,
            agg_data,
            obj_key: aggregate_keys,
          }

          if (c.is_my_aggregate_count) {
            if (c.name === "แชทที่ส่งให้ทีม") {
              c.total = await dispatch("getMyTeamAggregateCount", data)
              // Generate sub menu "แชทที่ส่งให้ทีม"
              if (_.get(agg_data, "teams_assignment")) {
                c.sub_menu = await dispatch(
                  "generateMenuChatAssignToTeam",
                  data
                )
              }
            } else if (c.name === "แชทของคุณ") {
              // Generate sub menu "แชทของคุณ"
              if (_.get(agg_data, "members_assignment")) {
                c.sub_menu = await dispatch("generateMenuMyChat", data)
              }
            } else {
              c.total = await dispatch("getMyAggregateCount", data)
            }
          } else {
            c.total = await dispatch("getAggregateCount", data)
          }
        }
      })
    })

    commit("setMenu", { index, menu })
  },
}

const getters = {
  path_list: (state, getters, rootState) => {
    const base_path = `/business/${_.get(rootState.business, "_id")}`
    return {
      smartchat: `${base_path}/smartchat`,
      contact: `${base_path}/contact`,
      new_contact: `${base_path}/contact/new_contact`,
      top_rank_chat_contact: `${base_path}/contact/top_rank_chat_contact`,
      greeting_no_chat: `${base_path}/contact/greeting_no_chat`,
      auto_reply: `${base_path}/auto-reply`,
      greeting_message: `${base_path}/auto-reply/greeting-message`,
      main_menu: `${base_path}/auto-reply/main-menu`,
      fallback: `${base_path}/auto-reply/fallback`,
      messenger: `${base_path}/auto-reply/messenger`,
      rich_menu: `${base_path}/auto-reply/rich-menu`,
      dashboard: `${base_path}/dashboard`,
      broadcast: `${base_path}/broadcast`,
      create_broadcast: `${base_path}/broadcast/create`,
      media_library: `${base_path}/media-library`,
      image: `${base_path}/media-library/image`,
      video: `${base_path}/media-library/video`,
      file: `${base_path}/media-library/file`,
      onboarding: `${base_path}/onboarding`,
      help: "https://help.oho.chat",
      setting: `${base_path}/setting`,
      setting_business: `${base_path}/setting/business`,
      setting_member: `${base_path}/setting/member`,
      setting_integration: `${base_path}/setting/integration`,
      setting_payment: `${base_path}/setting/payment`,
      setting_saved_reply: `${base_path}/setting/saved-reply`,
      setting_contact_channels: `${base_path}/setting/contact-channels`,
      setting_business_hours: `${base_path}/setting/business-hours`,
      setting_business_holidays: `${base_path}/setting/business-holidays`,
      setting_inform_admin: `${base_path}/setting/inform-admin`,
    }
  },
  base_path: (state, getters, rootState) => {
    return `/business/${_.get(rootState.business, "_id")}`
  },
  nav_menu: (state, getters) => {
    const res = _.map(state.menu_list, (m) => {
      const path = m.is_external_link ? m.path : `${getters.base_path}${m.path}`
      let sub_menu_list = []

      if (m.sub_menu_list) {
        sub_menu_list = _.map(m.sub_menu_list, (sub) => {
          const childs = _.map(sub.childs, (ch) => {
            return {
              ...ch,
              path: `${getters.base_path}${ch.path}`,
            }
          })

          return { ...sub, childs }
        })
      }

      return {
        ...m,
        path,
        sub_menu_list,
      }
    })

    return res
  },
}

export default { state, mutations, actions, getters }
