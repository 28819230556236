
import { mapState } from "vuex"
import _ from "lodash"

export default {
  props: {
    team_list: {
      type: Array,
      default: () => [],
    },
    onAdd: {
      type: Function,
      default: () => {},
    },
    onRemove: {
      type: Function,
      default: () => {},
    },
    is_show_add: {
      type: Boolean,
      default: false,
    },
    is_show_remove: {
      type: Boolean,
      default: false,
    },
    is_disabled_default_team: {
      type: Boolean,
      default: false,
    },
    is_member_setting: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      oho_member: (state) => state.oho_member.member,
    }),
    my_teams() {
      return _.get(this.oho_member, "teams", [])
    },
    team_id_list() {
      return this.team_list.map((team) => team._id)
    },
    team_member_remove_permission() {
      return this.$permission.validate("team.member.remove")
    },
    team_is_not_member_update_permission() {
      return this.$permission.validate("team.me-is-not-member.update")
    },
  },
  methods: {
    isDisabled(team) {
      if (this.is_disabled_default_team && team.type === "default") {
        return true
      } else {
        return false
      }
    },
    checkEnabledRemoveTeamBtn(team_id) {
      if (!this.team_is_not_member_update_permission) {
        if (this.team_member_remove_permission) {
          return this.my_teams.includes(team_id)
        }
      } else {
        return this.team_member_remove_permission
      }
    },
  },
}
