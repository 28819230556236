
import moment from "moment"
import _ from "lodash"

export default {
  props: {
    index: {
      type: Number,
      default: null,
    },
    activity: {
      type: Object,
      default: () => {},
    },
    prev_created_date: {
      type: String,
      default: "",
    },
  },
  computed: {
    is_auto_assign() {
      return _.get(this.activity, "style.is_auto_assign")
    },
  },
  methods: {
    getDiffTime(start, end) {
      return moment(start).diff(moment(end))
    },
    formatDiffTime(diffTime) {
      let seconds = diffTime / 1000
      let result = ""
      if (seconds < 60) {
        result = `+${Math.floor(seconds)} วินาที`
      } else if (seconds / 60 < 60) {
        result = `+${Math.floor(seconds / 60)} นาที`
      } else {
        result = `+${Math.floor(seconds / 3600)} ชั่วโมง`
        const remain_minute = Math.floor((seconds % 3600) / 60)
        if (remain_minute > 0) {
          result += ` ${remain_minute} นาที`
        }
      }
      return result
    },
  },
}
